@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
    --main-color   : #199A8E;
    --bg-main-color: #BCD6D3;
    --light-color  : #f0f3f2;
    --blue-color   : #014ED5;
    --shadow       : rgba(145, 158, 171, .2) 0px 2px 4px -1px, rgba(145, 158, 171, .14) 0px 4px 5px 0px, rgba(145, 158, 171, .12) 0px 1px 10px 0px;
    --font-family  : 'Encode Sans Expanded', sans-serif;
    --rating-color : #ffc908;
}

body {
    background-color: rgba(255, 255, 255, .8);
    position        : relative;
}

.loading {
    display        : flex;
    justify-content: center;
    align-items    : center;
    height         : 100vh !important;
}

.design-card {
    box-shadow   : rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.1) 0px 8px 10px -6px;
    padding      : 20px 30px;
    margin-top   : 30px;
    margin-bottom: 20px;
    border-radius: 15px;
    height       : inherit !important;
    width        : inherit !important;
}

.design-card-1 {
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.1) 0px 8px 10px -6px;
}

.design-card-1 tr th {
    padding: 5px 10px !important;
}

.top-hed {
    font-weight  : 700;
    font-size    : 24px;
    color        : #303972;
    text-shadow  : 2px 4px 3px rgba(0, 0, 0, 0.5) !important;
    padding      : 7px 20px;
    border       : 2px solid rgba(0, 0, 0, 0.4);
    border-radius: 50px;
}